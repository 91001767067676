var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import DownOutlined from '@ant-design/icons/DownOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';
import classNames from 'classnames';
import RcInputNumber from 'rc-input-number';
import * as React from 'react';
import { getMergedStatus, getStatusClassNames } from '../_util/statusUtils';
import ConfigProvider, { ConfigContext } from '../config-provider';
import DisabledContext from '../config-provider/DisabledContext';
import useSize from '../config-provider/hooks/useSize';
import { FormItemInputContext, NoFormStyle } from '../form/context';
import { NoCompactStyle, useCompactItemContext } from '../space/Compact';
import useStyle from './style';
const InputNumber = React.forwardRef((props, ref) => {
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const inputRef = React.useRef(null);
    React.useImperativeHandle(ref, () => inputRef.current);
    const { className, rootClassName, size: customizeSize, disabled: customDisabled, prefixCls: customizePrefixCls, addonBefore, addonAfter, prefix, bordered = true, readOnly, status: customStatus, controls } = props, others = __rest(props, ["className", "rootClassName", "size", "disabled", "prefixCls", "addonBefore", "addonAfter", "prefix", "bordered", "readOnly", "status", "controls"]);
    const prefixCls = getPrefixCls('input-number', customizePrefixCls);
    // Style
    const [wrapSSR, hashId] = useStyle(prefixCls);
    const { compactSize, compactItemClassnames } = useCompactItemContext(prefixCls, direction);
    let upIcon = React.createElement(UpOutlined, { className: `${prefixCls}-handler-up-inner` });
    let downIcon = React.createElement(DownOutlined, { className: `${prefixCls}-handler-down-inner` });
    const controlsTemp = typeof controls === 'boolean' ? controls : undefined;
    if (typeof controls === 'object') {
        upIcon =
            typeof controls.upIcon === 'undefined' ? (upIcon) : (React.createElement("span", { className: `${prefixCls}-handler-up-inner` }, controls.upIcon));
        downIcon =
            typeof controls.downIcon === 'undefined' ? (downIcon) : (React.createElement("span", { className: `${prefixCls}-handler-down-inner` }, controls.downIcon));
    }
    const { hasFeedback, status: contextStatus, isFormItemInput, feedbackIcon, } = React.useContext(FormItemInputContext);
    const mergedStatus = getMergedStatus(contextStatus, customStatus);
    const mergedSize = useSize((ctx) => { var _a; return (_a = customizeSize !== null && customizeSize !== void 0 ? customizeSize : compactSize) !== null && _a !== void 0 ? _a : ctx; });
    // ===================== Disabled =====================
    const disabled = React.useContext(DisabledContext);
    const mergedDisabled = customDisabled !== null && customDisabled !== void 0 ? customDisabled : disabled;
    const inputNumberClass = classNames({
        [`${prefixCls}-lg`]: mergedSize === 'large',
        [`${prefixCls}-sm`]: mergedSize === 'small',
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-borderless`]: !bordered,
        [`${prefixCls}-in-form-item`]: isFormItemInput,
    }, getStatusClassNames(prefixCls, mergedStatus), hashId);
    const wrapperClassName = `${prefixCls}-group`;
    // eslint-disable-next-line react/jsx-no-useless-fragment
    const suffixNode = hasFeedback && React.createElement(React.Fragment, null, feedbackIcon);
    const element = (React.createElement(RcInputNumber, Object.assign({ ref: inputRef, disabled: mergedDisabled, className: classNames(className, rootClassName, compactItemClassnames), upHandler: upIcon, downHandler: downIcon, prefixCls: prefixCls, readOnly: readOnly, controls: controlsTemp, prefix: prefix, suffix: suffixNode, addonAfter: addonAfter && (React.createElement(NoCompactStyle, null,
            React.createElement(NoFormStyle, { override: true, status: true }, addonAfter))), addonBefore: addonBefore && (React.createElement(NoCompactStyle, null,
            React.createElement(NoFormStyle, { override: true, status: true }, addonBefore))), classNames: {
            input: inputNumberClass,
        }, classes: {
            affixWrapper: classNames(getStatusClassNames(`${prefixCls}-affix-wrapper`, mergedStatus, hasFeedback), {
                [`${prefixCls}-affix-wrapper-sm`]: mergedSize === 'small',
                [`${prefixCls}-affix-wrapper-lg`]: mergedSize === 'large',
                [`${prefixCls}-affix-wrapper-rtl`]: direction === 'rtl',
                [`${prefixCls}-affix-wrapper-borderless`]: !bordered,
            }, hashId),
            wrapper: classNames({
                [`${wrapperClassName}-rtl`]: direction === 'rtl',
                [`${prefixCls}-wrapper-disabled`]: mergedDisabled,
            }, hashId),
            group: classNames({
                [`${prefixCls}-group-wrapper-sm`]: mergedSize === 'small',
                [`${prefixCls}-group-wrapper-lg`]: mergedSize === 'large',
                [`${prefixCls}-group-wrapper-rtl`]: direction === 'rtl',
            }, getStatusClassNames(`${prefixCls}-group-wrapper`, mergedStatus, hasFeedback), hashId),
        } }, others)));
    return wrapSSR(element);
});
const TypedInputNumber = InputNumber;
/** @private Internal Component. Do not use in your production. */
const PureInputNumber = (props) => (React.createElement(ConfigProvider, { theme: { components: { InputNumber: { handleVisible: true } } } },
    React.createElement(InputNumber, Object.assign({}, props))));
if (process.env.NODE_ENV !== 'production') {
    TypedInputNumber.displayName = 'InputNumber';
}
TypedInputNumber._InternalPanelDoNotUseOrYouWillBeFired = PureInputNumber;
export default TypedInputNumber;
