import classNames from 'classnames';
import toArray from 'rc-util/lib/Children/toArray';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import Popover from '../popover';
import { cloneElement } from '../_util/reactNode';
import Avatar from './avatar';
import AvatarContext from './AvatarContext';
import useStyle from './style';
const AvatarContextProvider = (props) => {
    const { size, shape } = React.useContext(AvatarContext);
    const avatarContextValue = React.useMemo(() => ({ size: props.size || size, shape: props.shape || shape }), [props.size, props.shape, size, shape]);
    return (React.createElement(AvatarContext.Provider, { value: avatarContextValue }, props.children));
};
const Group = (props) => {
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, className, rootClassName, style, maxCount, maxStyle, size, shape, maxPopoverPlacement = 'top', maxPopoverTrigger = 'hover', children, } = props;
    const prefixCls = getPrefixCls('avatar', customizePrefixCls);
    const groupPrefixCls = `${prefixCls}-group`;
    const [wrapSSR, hashId] = useStyle(prefixCls);
    const cls = classNames(groupPrefixCls, {
        [`${groupPrefixCls}-rtl`]: direction === 'rtl',
    }, className, rootClassName, hashId);
    const childrenWithProps = toArray(children).map((child, index) => cloneElement(child, { key: `avatar-key-${index}` }));
    const numOfChildren = childrenWithProps.length;
    if (maxCount && maxCount < numOfChildren) {
        const childrenShow = childrenWithProps.slice(0, maxCount);
        const childrenHidden = childrenWithProps.slice(maxCount, numOfChildren);
        childrenShow.push(React.createElement(Popover, { key: "avatar-popover-key", content: childrenHidden, trigger: maxPopoverTrigger, placement: maxPopoverPlacement, overlayClassName: `${groupPrefixCls}-popover` },
            React.createElement(Avatar, { style: maxStyle }, `+${numOfChildren - maxCount}`)));
        return wrapSSR(React.createElement(AvatarContextProvider, { shape: shape, size: size },
            React.createElement("div", { className: cls, style: style }, childrenShow)));
    }
    return wrapSSR(React.createElement(AvatarContextProvider, { shape: shape, size: size },
        React.createElement("div", { className: cls, style: style }, childrenWithProps)));
};
export default Group;
