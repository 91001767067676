import { genComponentStyleHook, mergeToken } from '../../theme/internal';
import { resetComponent } from '../../style';
// ============================== Shared ==============================
const genSharedBackTopStyle = (token) => {
    const { componentCls, backTopFontSize, backTopSize, zIndexPopup } = token;
    return {
        [componentCls]: Object.assign(Object.assign({}, resetComponent(token)), { position: 'fixed', insetInlineEnd: token.backTopInlineEnd, insetBlockEnd: token.backTopBlockEnd, zIndex: zIndexPopup, width: 40, height: 40, cursor: 'pointer', '&:empty': {
                display: 'none',
            }, [`${componentCls}-content`]: {
                width: backTopSize,
                height: backTopSize,
                overflow: 'hidden',
                color: token.backTopColor,
                textAlign: 'center',
                backgroundColor: token.backTopBackground,
                borderRadius: backTopSize,
                transition: `all ${token.motionDurationMid}`,
                '&:hover': {
                    backgroundColor: token.backTopHoverBackground,
                    transition: `all ${token.motionDurationMid}`,
                },
            }, 
            // change to .backtop .backtop-icon
            [`${componentCls}-icon`]: {
                fontSize: backTopFontSize,
                lineHeight: `${backTopSize}px`,
            } }),
    };
};
const genMediaBackTopStyle = (token) => {
    const { componentCls } = token;
    return {
        [`@media (max-width: ${token.screenMD}px)`]: {
            [componentCls]: {
                insetInlineEnd: token.backTopInlineEndMD,
            },
        },
        [`@media (max-width: ${token.screenXS}px)`]: {
            [componentCls]: {
                insetInlineEnd: token.backTopInlineEndXS,
            },
        },
    };
};
// ============================== Export ==============================
export default genComponentStyleHook('BackTop', (token) => {
    const { fontSizeHeading3, colorTextDescription, colorTextLightSolid, colorText, controlHeightLG, } = token;
    const backTopToken = mergeToken(token, {
        backTopBackground: colorTextDescription,
        backTopColor: colorTextLightSolid,
        backTopHoverBackground: colorText,
        backTopFontSize: fontSizeHeading3,
        backTopSize: controlHeightLG,
        backTopBlockEnd: controlHeightLG * 1.25,
        backTopInlineEnd: controlHeightLG * 2.5,
        backTopInlineEndMD: controlHeightLG * 1.5,
        backTopInlineEndXS: controlHeightLG * 0.5,
    });
    return [genSharedBackTopStyle(backTopToken), genMediaBackTopStyle(backTopToken)];
}, (token) => ({
    zIndexPopup: token.zIndexBase + 10,
}));
