import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import InputNumber from '../../input-number';
const ColorSteppers = ({ prefixCls, min = 0, max = 100, value, onChange, className, formatter, }) => {
    const colorSteppersPrefixCls = `${prefixCls}-steppers`;
    const [stepValue, setStepValue] = useState(value);
    // Update step value
    useEffect(() => {
        if (!Number.isNaN(value)) {
            setStepValue(value);
        }
    }, [value]);
    return (React.createElement(InputNumber, { className: classNames(colorSteppersPrefixCls, className), min: min, max: max, value: stepValue, formatter: formatter, size: "small", onChange: (step) => {
            if (!value) {
                setStepValue(step || 0);
            }
            onChange === null || onChange === void 0 ? void 0 : onChange(step);
        } }));
};
export default ColorSteppers;
