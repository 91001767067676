import { genComponentStyleHook, mergeToken } from '../../theme/internal';
// ============================== Styles ==============================
const genBaseStyle = (token) => {
    const { componentCls, lineHeightHeading3, iconCls, padding, paddingXL, paddingXS, paddingLG, marginXS, lineHeight, } = token;
    return {
        // Result
        [componentCls]: {
            padding: `${paddingLG * 2}px ${paddingXL}px`,
            // RTL
            '&-rtl': {
                direction: 'rtl',
            },
        },
        // Exception Status image
        [`${componentCls} ${componentCls}-image`]: {
            width: token.imageWidth,
            height: token.imageHeight,
            margin: 'auto',
        },
        [`${componentCls} ${componentCls}-icon`]: {
            marginBottom: paddingLG,
            textAlign: 'center',
            [`& > ${iconCls}`]: {
                fontSize: token.iconFontSize,
            },
        },
        [`${componentCls} ${componentCls}-title`]: {
            color: token.colorTextHeading,
            fontSize: token.titleFontSize,
            lineHeight: lineHeightHeading3,
            marginBlock: marginXS,
            textAlign: 'center',
        },
        [`${componentCls} ${componentCls}-subtitle`]: {
            color: token.colorTextDescription,
            fontSize: token.subtitleFontSize,
            lineHeight,
            textAlign: 'center',
        },
        [`${componentCls} ${componentCls}-content`]: {
            marginTop: paddingLG,
            padding: `${paddingLG}px ${padding * 2.5}px`,
            backgroundColor: token.colorFillAlter,
        },
        [`${componentCls} ${componentCls}-extra`]: {
            margin: token.extraMargin,
            textAlign: 'center',
            '& > *': {
                marginInlineEnd: paddingXS,
                '&:last-child': {
                    marginInlineEnd: 0,
                },
            },
        },
    };
};
const genStatusIconStyle = (token) => {
    const { componentCls, iconCls } = token;
    return {
        [`${componentCls}-success ${componentCls}-icon > ${iconCls}`]: {
            color: token.resultSuccessIconColor,
        },
        [`${componentCls}-error ${componentCls}-icon > ${iconCls}`]: {
            color: token.resultErrorIconColor,
        },
        [`${componentCls}-info ${componentCls}-icon > ${iconCls}`]: {
            color: token.resultInfoIconColor,
        },
        [`${componentCls}-warning ${componentCls}-icon > ${iconCls}`]: {
            color: token.resultWarningIconColor,
        },
    };
};
const genResultStyle = (token) => [
    genBaseStyle(token),
    genStatusIconStyle(token),
];
// ============================== Export ==============================
const getStyle = (token) => genResultStyle(token);
export default genComponentStyleHook('Result', (token) => {
    const resultInfoIconColor = token.colorInfo;
    const resultErrorIconColor = token.colorError;
    const resultSuccessIconColor = token.colorSuccess;
    const resultWarningIconColor = token.colorWarning;
    const resultToken = mergeToken(token, {
        resultInfoIconColor,
        resultErrorIconColor,
        resultSuccessIconColor,
        resultWarningIconColor,
        imageWidth: 250,
        imageHeight: 295,
    });
    return [getStyle(resultToken)];
}, (token) => ({
    titleFontSize: token.fontSizeHeading3,
    subtitleFontSize: token.fontSize,
    iconFontSize: token.fontSizeHeading3 * 3,
    extraMargin: `${token.paddingLG}px 0 0 0`,
}));
