import useMemo from 'rc-util/lib/hooks/useMemo';
import isEqual from 'rc-util/lib/isEqual';
import { defaultConfig } from '../../theme/internal';
export default function useTheme(theme, parentTheme) {
    const themeConfig = theme || {};
    const parentThemeConfig = themeConfig.inherit === false || !parentTheme ? defaultConfig : parentTheme;
    return useMemo(() => {
        if (!theme) {
            return parentTheme;
        }
        // Override
        const mergedComponents = Object.assign({}, parentThemeConfig.components);
        Object.keys(theme.components || {}).forEach((componentName) => {
            mergedComponents[componentName] = Object.assign(Object.assign({}, mergedComponents[componentName]), theme.components[componentName]);
        });
        // Base token
        return Object.assign(Object.assign(Object.assign({}, parentThemeConfig), themeConfig), { token: Object.assign(Object.assign({}, parentThemeConfig.token), themeConfig.token), components: mergedComponents });
    }, [themeConfig, parentThemeConfig], (prev, next) => prev.some((prevTheme, index) => {
        const nextTheme = next[index];
        return !isEqual(prevTheme, nextTheme, true);
    }));
}
