var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import * as React from 'react';
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined';
import Button from '../button';
import { ConfigContext } from '../config-provider';
import Space from '../space';
import { useCompactItemContext } from '../space/Compact';
import Dropdown from './dropdown';
import useStyle from './style';
const DropdownButton = (props) => {
    const { getPopupContainer: getContextPopupContainer, getPrefixCls, direction, } = React.useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, type = 'default', danger, disabled, loading, onClick, htmlType, children, className, menu, arrow, autoFocus, overlay, trigger, align, open, onOpenChange, placement, getPopupContainer, href, icon = React.createElement(EllipsisOutlined, null), title, buttonsRender = (buttons) => buttons, mouseEnterDelay, mouseLeaveDelay, overlayClassName, overlayStyle, destroyPopupOnHide, dropdownRender } = props, restProps = __rest(props, ["prefixCls", "type", "danger", "disabled", "loading", "onClick", "htmlType", "children", "className", "menu", "arrow", "autoFocus", "overlay", "trigger", "align", "open", "onOpenChange", "placement", "getPopupContainer", "href", "icon", "title", "buttonsRender", "mouseEnterDelay", "mouseLeaveDelay", "overlayClassName", "overlayStyle", "destroyPopupOnHide", "dropdownRender"]);
    const prefixCls = getPrefixCls('dropdown', customizePrefixCls);
    const buttonPrefixCls = `${prefixCls}-button`;
    const [wrapSSR, hashId] = useStyle(prefixCls);
    const dropdownProps = {
        menu,
        arrow,
        autoFocus,
        align,
        disabled,
        trigger: disabled ? [] : trigger,
        onOpenChange,
        getPopupContainer: getPopupContainer || getContextPopupContainer,
        mouseEnterDelay,
        mouseLeaveDelay,
        overlayClassName,
        overlayStyle,
        destroyPopupOnHide,
        dropdownRender,
    };
    const { compactSize, compactItemClassnames } = useCompactItemContext(prefixCls, direction);
    const classes = classNames(buttonPrefixCls, compactItemClassnames, className, hashId);
    if ('overlay' in props) {
        dropdownProps.overlay = overlay;
    }
    if ('open' in props) {
        dropdownProps.open = open;
    }
    if ('placement' in props) {
        dropdownProps.placement = placement;
    }
    else {
        dropdownProps.placement = direction === 'rtl' ? 'bottomLeft' : 'bottomRight';
    }
    const leftButton = (React.createElement(Button, { type: type, danger: danger, disabled: disabled, loading: loading, onClick: onClick, htmlType: htmlType, href: href, title: title }, children));
    const rightButton = React.createElement(Button, { type: type, danger: danger, icon: icon });
    const [leftButtonToRender, rightButtonToRender] = buttonsRender([leftButton, rightButton]);
    return wrapSSR(React.createElement(Space.Compact, Object.assign({ className: classes, size: compactSize, block: true }, restProps),
        leftButtonToRender,
        React.createElement(Dropdown, Object.assign({}, dropdownProps), rightButtonToRender)));
};
DropdownButton.__ANT_BUTTON = true;
export default DropdownButton;
