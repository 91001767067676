import * as React from 'react';
import RightOutlined from '@ant-design/icons/RightOutlined';
import classNames from 'classnames';
import RcCollapse from 'rc-collapse';
import toArray from 'rc-util/lib/Children/toArray';
import omit from 'rc-util/lib/omit';
import initCollapseMotion from '../_util/motion';
import { cloneElement } from '../_util/reactNode';
import { devUseWarning } from '../_util/warning';
import { ConfigContext } from '../config-provider';
import useSize from '../config-provider/hooks/useSize';
import CollapsePanel from './CollapsePanel';
import useStyle from './style';
const Collapse = React.forwardRef((props, ref) => {
    const { getPrefixCls, direction, collapse } = React.useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, className, rootClassName, style, bordered = true, ghost, size: customizeSize, expandIconPosition = 'start', children, expandIcon, } = props;
    const mergedSize = useSize((ctx) => { var _a; return (_a = customizeSize !== null && customizeSize !== void 0 ? customizeSize : ctx) !== null && _a !== void 0 ? _a : 'middle'; });
    const prefixCls = getPrefixCls('collapse', customizePrefixCls);
    const rootPrefixCls = getPrefixCls();
    const [wrapSSR, hashId] = useStyle(prefixCls);
    if (process.env.NODE_ENV !== 'production') {
        const warning = devUseWarning('Collapse');
        // Warning if use legacy type `expandIconPosition`
        warning(expandIconPosition !== 'left' && expandIconPosition !== 'right', 'deprecated', '`expandIconPosition` with `left` or `right` is deprecated. Please use `start` or `end` instead.');
    }
    // Align with logic position
    const mergedExpandIconPosition = React.useMemo(() => {
        if (expandIconPosition === 'left') {
            return 'start';
        }
        return expandIconPosition === 'right' ? 'end' : expandIconPosition;
    }, [expandIconPosition]);
    const renderExpandIcon = (panelProps = {}) => {
        const icon = (expandIcon ? (expandIcon(panelProps)) : (React.createElement(RightOutlined, { rotate: panelProps.isActive ? 90 : undefined })));
        return cloneElement(icon, () => ({
            className: classNames(icon.props.className, `${prefixCls}-arrow`),
        }));
    };
    const collapseClassName = classNames(`${prefixCls}-icon-position-${mergedExpandIconPosition}`, {
        [`${prefixCls}-borderless`]: !bordered,
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-ghost`]: !!ghost,
        [`${prefixCls}-${mergedSize}`]: mergedSize !== 'middle',
    }, collapse === null || collapse === void 0 ? void 0 : collapse.className, className, rootClassName, hashId);
    const openMotion = Object.assign(Object.assign({}, initCollapseMotion(rootPrefixCls)), { motionAppear: false, leavedClassName: `${prefixCls}-content-hidden` });
    const items = React.useMemo(() => children
        ? toArray(children).map((child, index) => {
            var _a, _b;
            if ((_a = child.props) === null || _a === void 0 ? void 0 : _a.disabled) {
                const key = (_b = child.key) !== null && _b !== void 0 ? _b : String(index);
                const { disabled, collapsible } = child.props;
                const childProps = Object.assign(Object.assign({}, omit(child.props, ['disabled'])), { key, collapsible: collapsible !== null && collapsible !== void 0 ? collapsible : (disabled ? 'disabled' : undefined) });
                return cloneElement(child, childProps);
            }
            return child;
        })
        : null, [children]);
    return wrapSSR(React.createElement(RcCollapse, Object.assign({ ref: ref, openMotion: openMotion }, omit(props, ['rootClassName']), { expandIcon: renderExpandIcon, prefixCls: prefixCls, className: collapseClassName, style: Object.assign(Object.assign({}, collapse === null || collapse === void 0 ? void 0 : collapse.style), style) }), items));
});
if (process.env.NODE_ENV !== 'production') {
    Collapse.displayName = 'Collapse';
}
export default Object.assign(Collapse, { Panel: CollapsePanel });
