var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Divider from '../divider';
import PanelPicker from './components/PanelPicker';
import PanelPresets from './components/PanelPresets';
import { PanelPickerProvider, PanelPresetsProvider } from './context';
const ColorPickerPanel = (props) => {
    const { prefixCls, presets, panelRender, color, onChange, onClear } = props, injectProps = __rest(props, ["prefixCls", "presets", "panelRender", "color", "onChange", "onClear"]);
    const colorPickerPanelPrefixCls = `${prefixCls}-inner-content`;
    // ==== Inject props ===
    const panelPickerProps = Object.assign({ prefixCls, value: color, onChange,
        onClear }, injectProps);
    const panelPresetsProps = React.useMemo(() => ({
        prefixCls,
        value: color,
        presets,
        onChange,
    }), [prefixCls, color, presets, onChange]);
    // ====================== Render ======================
    const innerPanel = (React.createElement(React.Fragment, null,
        React.createElement(PanelPicker, null),
        Array.isArray(presets) && React.createElement(Divider, { className: `${colorPickerPanelPrefixCls}-divider` }),
        React.createElement(PanelPresets, null)));
    return (React.createElement(PanelPickerProvider, { value: panelPickerProps },
        React.createElement(PanelPresetsProvider, { value: panelPresetsProps },
            React.createElement("div", { className: colorPickerPanelPrefixCls }, typeof panelRender === 'function'
                ? panelRender(innerPanel, {
                    components: {
                        Picker: PanelPicker,
                        Presets: PanelPresets,
                    },
                })
                : innerPanel))));
};
if (process.env.NODE_ENV !== 'production') {
    ColorPickerPanel.displayName = 'ColorPickerPanel';
}
export default ColorPickerPanel;
