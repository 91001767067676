var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useCacheToken } from '@ant-design/cssinjs';
import version from '../version';
import { defaultTheme, DesignTokenContext } from './context';
import defaultSeedToken from './themes/seed';
import formatToken from './util/alias';
export const getComputedToken = (originToken, overrideToken, theme) => {
    const derivativeToken = theme.getDerivativeToken(originToken);
    const { override } = overrideToken, components = __rest(overrideToken, ["override"]);
    // Merge with override
    let mergedDerivativeToken = Object.assign(Object.assign({}, derivativeToken), { override });
    // Format if needed
    mergedDerivativeToken = formatToken(mergedDerivativeToken);
    if (components) {
        Object.entries(components).forEach(([key, value]) => {
            const { theme: componentTheme } = value, componentTokens = __rest(value, ["theme"]);
            let mergedComponentToken = componentTokens;
            if (componentTheme) {
                mergedComponentToken = getComputedToken(Object.assign(Object.assign({}, mergedDerivativeToken), componentTokens), {
                    override: componentTokens,
                }, componentTheme);
            }
            mergedDerivativeToken[key] = mergedComponentToken;
        });
    }
    return mergedDerivativeToken;
};
// ================================== Hook ==================================
export default function useToken() {
    const { token: rootDesignToken, hashed, theme, override } = React.useContext(DesignTokenContext);
    const salt = `${version}-${hashed || ''}`;
    const mergedTheme = theme || defaultTheme;
    const [token, hashId] = useCacheToken(mergedTheme, [defaultSeedToken, rootDesignToken], {
        salt,
        override,
        getComputedToken,
        // formatToken will not be consumed after 1.15.0 with getComputedToken.
        // But token will break if @ant-design/cssinjs is under 1.15.0 without it
        formatToken,
    });
    return [mergedTheme, token, hashed ? hashId : ''];
}
