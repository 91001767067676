import classNames from 'classnames';
import { Popup } from 'rc-tooltip';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import useStyle from './style';
import { parseColor } from './util';
/** @private Internal Component. Do not use in your production. */
const PurePanel = (props) => {
    const { prefixCls: customizePrefixCls, className, placement = 'top', title, color, overlayInnerStyle, } = props;
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('tooltip', customizePrefixCls);
    const [wrapSSR, hashId] = useStyle(prefixCls, true);
    // Color
    const colorInfo = parseColor(prefixCls, color);
    const arrowContentStyle = colorInfo.arrowStyle;
    const formattedOverlayInnerStyle = Object.assign(Object.assign({}, overlayInnerStyle), colorInfo.overlayStyle);
    const cls = classNames(hashId, prefixCls, `${prefixCls}-pure`, `${prefixCls}-placement-${placement}`, className, colorInfo.className);
    return wrapSSR(React.createElement("div", { className: cls, style: arrowContentStyle },
        React.createElement("div", { className: `${prefixCls}-arrow` }),
        React.createElement(Popup, Object.assign({}, props, { className: hashId, prefixCls: prefixCls, overlayInnerStyle: formattedOverlayInnerStyle }), title)));
};
export default PurePanel;
